import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Disclaimer = styled.div`
  text-align: center;
`

export const Map = styled.div`
  margin: ${g(3)} auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 7%;
    margin-right: 13%;
    width: 80%;
  }
`

export const Table = styled.div`
  margin-bottom: ${g(2)};
  margin-top: ${g(3)};
`

export const Title = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  display: flex;
  margin-bottom: ${g(3)};
`
