import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import { ScrollObserver } from '@pretto/bricks/core/utility/observer'
import RatesGraphLegend from '@pretto/bricks/website/rates/components/RatesGraphLegend'
import { Column } from '@pretto/bricks/website/rates/components/RatesGrid/styles'
import ContentBlock, { defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import PropTypes from 'prop-types'
import { cloneElement } from 'react'

import * as S from './styles'

const RatesRowDistributionText = ({ content, graph, legend, overrides, select: Select, title }) => (
  <>
    <Column>
      <S.Title>{title}</S.Title>

      <ContentBlock overrides={overrides}>{content}</ContentBlock>
    </Column>

    <Column>
      <Card format="line" overflowVisible variant="neutral-1-20">
        <Content>
          <Select>{({ duration }) => <>Distribution des taux pour un prêt {duration}.</>}</Select>

          <S.Graph>
            <ScrollObserver>
              {(ref, isVisible) => (
                <>
                  {cloneElement(graph, {
                    isVisible,
                    ref,
                  })}
                </>
              )}
            </ScrollObserver>
          </S.Graph>

          <RatesGraphLegend items={legend} />
        </Content>
      </Card>
    </Column>
  </>
)

RatesRowDistributionText.defaultProps = {
  overrides: defaultOverrides,
}

RatesRowDistributionText.propTypes = {
  /** Markdown content to populate the text block. */
  content: PropTypes.string.isRequired,
  /** Wages graph. */
  graph: PropTypes.node.isRequired,
  /** Legend. */
  legend: PropTypes.array.isRequired,
  /** ContentBlock overrides. */
  overrides: PropTypes.object,
  /** Select component. */
  select: PropTypes.elementType.isRequired,
  /** Row title. */
  title: PropTypes.string.isRequired,
}

export default RatesRowDistributionText
