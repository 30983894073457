import Text from '@pretto/bricks/components/typography/Text'
import { Column } from '@pretto/bricks/website/rates/components/RatesGrid/styles'
import DataTable from '@pretto/bricks/website/shared/components/DataTable'
import ContentBlock, { defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import PropTypes from 'prop-types'
import { cloneElement } from 'react'

import * as S from './styles'

const RatesRowRegionsText = ({
  content,
  customState,
  data,
  graph,
  overrides,
  select: Select,
  setCustomState,
  tableData,
  title,
}) => {
  const handleStateSelected = stateSelected => {
    setCustomState({ stateSelected })
  }

  const selectedRowIndex = data.findIndex(({ regionSlug }) => regionSlug === customState.stateSelected)

  return (
    <>
      <Column>
        <S.Title>{title}</S.Title>
      </Column>

      <Column />

      <Column>
        <ContentBlock overrides={overrides}>{content}</ContentBlock>

        <S.Map>{cloneElement(graph, { onStateSelected: handleStateSelected })}</S.Map>
      </Column>

      <Column>
        <Select>{({ duration }) => <>Meilleurs taux par région pour un prêt {duration}.</>}</Select>

        <S.Table>
          <DataTable
            data={[['Régions', 'Meilleurs taux', 'Taux ordinaires'], ...tableData]}
            headerRowsCount={1}
            selectedRowIndex={selectedRowIndex}
            nowrap
          />
        </S.Table>

        <S.Disclaimer>
          <Text size="x-small" variant="neutral-1-40">
            Nous n’avons actuellement pas assez de données concernant la Corse et les DOM-TOM.
          </Text>
        </S.Disclaimer>
      </Column>
    </>
  )
}

RatesRowRegionsText.defaultProps = {
  overrides: defaultOverrides,
}

RatesRowRegionsText.propTypes = {
  /** Markdown content to populate the text block. */
  content: PropTypes.string.isRequired,
  /** Custom state getter. */
  customState: PropTypes.object.isRequired,
  /** Raw data. */
  data: PropTypes.array.isRequired,
  /** Map component. */
  graph: PropTypes.node.isRequired,
  /** ContentBlock overrides. */
  overrides: PropTypes.object.isRequired,
  /** Select component. */
  select: PropTypes.elementType.isRequired,
  /** Custom state setter. */
  setCustomState: PropTypes.func.isRequired,
  /** Data to be consumed by the table. */
  tableData: PropTypes.array.isRequired,
  /** Row title. */
  title: PropTypes.string.isRequired,
}

export default RatesRowRegionsText
