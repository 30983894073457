import { g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Graph = styled.div`
  margin: ${g(4)} 0;
`

export const Title = styled.h2`
  ${({ theme }) => theme.typos.heading4};
  display: flex;
  margin-bottom: ${g(3)};
`
